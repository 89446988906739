import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/index";

Vue.use(VueRouter)

import { routes } from "./utils/routes"

const DEFAULT_TITLE = 'Okipo';

const router = new VueRouter({
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {
    if (router.app.$keycloak && !router.app.$keycloak.authenticated) {
        router.app.$keycloak.login({ redirectUri: `${process.env.VUE_APP_REDIRECT}/#${to.fullPath}` });
    } else {
        document.title = DEFAULT_TITLE + " – " + to.meta.pageTitle || DEFAULT_TITLE;
        let goNext = false;
        // Allow navigation if user has at least a role from the list
        if (to.matched.some(record => record.meta.requiredRoles && record.meta.requiredRoles.length > 0)) {
            const roles = to.meta.requiredRoles;
            if (roles.some((role) => router.app.$keycloak.hasRealmRole(role))) {
                goNext = true;
            } else {
                // Blocks the navigation if no role matches
                goNext = false;
            }
        } else { // No roles required
            goNext = true;
        }
        if (goNext) {
            console.log("User is synced", store.state.loggedUser.synced)
            if (!store.state.loggedUser.synced) {
                store.dispatch("loggedUser/loadUserData").then(() => {
                    console.log("User synced before route entering", store.state.loggedUser.synced)
                    next();
                })
            } else {
                next();
            }
        } else {
            next('/')
        }
    }
}
)
router.afterEach(() => {
    setTimeout(() => {
        if (typeof window !== 'undefined' && typeof window.fbq === 'function') {
            window.fbq('track', 'PageView', )
        }
      }, 500); // Delay just enough to ensure fbq is ready

})
export default router
