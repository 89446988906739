import Vue from 'vue'
import Vuex from 'vuex'

import loggedUser from './modules/user/loggedUser'
import notification from './modules/user/notification'
import selectedUserAddress from './modules/user/selectedUserAddress'
import userAddresses from './modules/user/userAddresses'
import addressBalance from './modules/address/addressBalance'
import userTags from './modules/user/userTags'
import alert from './modules/alert'
import fees from './modules/analytics/fees'
import portfolioAllocation from './modules/analytics/portfolioAllocation'
import converter from './modules/tool/converter'
import transactionCount from './modules/analytics/transactionCount'
import coin from './modules/analytics/coin'
import nft from './modules/analytics/nft'
import account from './modules/analytics/account'
import transaction from './modules/analytics/transaction'
import payment from './modules/payment/payment'
import newsFeed from './modules/market/newsFeed'
import tax from './modules/tax/tax'
import externalSource from './modules/externalData/externalSource'
import financial from './modules/financial/financial'
import accountant from './modules/accountant/accountant'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        alert, // Alerts management store
        loggedUser, // Logged user store
        selectedUserAddress, // Target user address store
        userAddresses, // User addresses store
        userTags, // User tags store
        addressBalance, // Address balances store
        fees, // Fees store,
        portfolioAllocation, // Portfolio allocation store
        converter, // Converter store
        notification, // User notifications store
        transactionCount, // Transaction count store
        coin, // Sentiment store
        nft, // NFT store
        account, // Account store
        transaction, // Transaction store
        payment, // Payment store
        newsFeed, // News store
        tax, // Tax store
        externalSource, // External data store
        financial, // Financial store
        accountant, // Accountant store
    },
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    }
})