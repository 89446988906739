var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-navigation-drawer',{attrs:{"bottom":"","app":"","clipped":"","mobile-breakpoint":"960"},model:{value:(_vm.navigationMenu),callback:function ($$v) {_vm.navigationMenu=$$v},expression:"navigationMenu"}},[_c('navigation-bar-component',{attrs:{"toggle":_vm.navigationMenu}})],1):_vm._e(),_c('v-app-bar',{attrs:{"height":"65x","app":"","color":_vm.$vuetify.theme.dark ? 'background' : 'background',"elevation":"0"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-app-bar-nav-icon',{attrs:{"color":"primary"},on:{"click":function($event){_vm.navigationMenu = !_vm.navigationMenu}}}):_vm._e(),_c('v-toolbar-title',[(!_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"pt-3 pl-3"},[_c('v-badge',{attrs:{"color":_vm.$vuetify.theme.dark ? 'background' : 'background',"content":_vm.$t('app.version'),"offset-x":"7","offset-y":"10"}},[_c('v-img',{attrs:{"src":_vm.getLogoImage,"max-width":"100","contain":""},on:{"click":function($event){return _vm.$router.push('/')}}})],1)],1):_c('div',{staticClass:"pt-2"},[_c('v-badge',{attrs:{"color":_vm.$vuetify.theme.dark ? 'background' : 'background',"content":_vm.$t('app.version'),"offset-x":"7","offset-y":"10"}},[_c('v-img',{attrs:{"src":_vm.getLogoImage,"max-width":"100","contain":""},on:{"click":function($event){return _vm.$router.push('/')}}})],1)],1)]),_c('v-spacer'),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-toolbar-items',[_c('v-btn',{class:{ 'navigation-button-active': _vm.$route.path === '/overview' },attrs:{"text":"","link":"","to":"/overview"}},[_vm._v(_vm._s(_vm.$t("user.overviewPageButton")))]),_c('v-btn',{class:{
          'navigation-button-active':
            _vm.$route.path === '/connection-management',
        },attrs:{"text":"","link":"","to":"/connection-management"}},[_vm._v(" "+_vm._s(_vm.$t("connection.connectionManagement"))+" ")]),_c('v-btn',{class:{ 'navigation-button-active': _vm.$route.path === '/tax-center' },attrs:{"text":"","link":"","to":"/tax-center"}},[_vm._v(" "+_vm._s(_vm.$t("tax.taxCenterPageButton"))+" ")]),_c('v-btn',{class:{
          'navigation-button-active': _vm.$route.path === '/pricing-plans',
        },attrs:{"text":"","link":"","to":"/pricing-plans"}},[_vm._v(" "+_vm._s(_vm.$t("plan.pricingPlans"))+" ")])],1):_vm._e(),_c('v-spacer'),(_vm.isLogged)?[(_vm.isAnyAddressUpdating)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"300px","min-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-progress-circular',_vm._g({staticClass:"ma-3 fixed",attrs:{"size":"20","width":"2","indeterminate":""}},on))]}}],null,false,4214837544)},[_c('span',[_vm._v(_vm._s(_vm.$t("message.updatingAddress")))])]):_vm._e(),_c('div',[_c('v-icon',{staticClass:"ma-2",attrs:{"color":"primary","size":"21px"},on:{"click":_vm.changeVisibility}},[_vm._v(_vm._s(_vm.getVisibilityIcon))])],1),_c('div',{staticClass:"mx-3"},[_c('notification-component')],1),_c('v-menu',{attrs:{"offset-y":"","rounded":"","right":"","open-on-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"primary--text ma-2"},on),[_vm._v("mdi-account-circle-outline")])]}}],null,false,3811168318)},[_c('profile-dropdown-component')],1)]:[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.login}},[_c('span',{staticClass:"ma-2 accent--text"},[_vm._v("Login")]),_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-login")])],1)]],2),_c('v-container',{staticClass:"pa-5 container-custom",attrs:{"fluid":""}},[_c('v-main',[(_vm.link.id && _vm.link.status == 'PENDING')?_c('v-alert',{attrs:{"dense":"","icon":"mdi-account-check-outline","outlined":"","type":"info"}},[_c('v-row',{attrs:{"align":'center'}},[_c('v-col',[_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(
                _vm.$t('account.accountantPendingInfo', {
                  accountantName: _vm.accountant.fullName,
                })
              )}})]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":"","elevation":"","color":"accent"},on:{"click":function($event){return _vm.$router.push({
                  name: 'User Settings Page',
                  params: { tab: 'accountant-tab' },
                })}}},[_c('span',[_vm._v(_vm._s(_vm.$t("account.acceptRequestButton")))]),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)],1)],1)],1):_vm._e(),_c('router-view',{key:_vm.$route.fullPath}),_c('snack-bar-alert')],1)],1),(_vm.$vuetify.breakpoint.xs)?_c('v-btn',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"}],staticClass:"white--text",attrs:{"fab":"","dark":"","fixed":"","bottom":"","small":"","right":"","color":"accent"},on:{"click":_vm.toTop}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }