import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        paymentData: null,
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        storePaymentData(state, data) {
            state.paymentData = data;
        }
    },
    actions: {
        createCheckoutSession(store, payload) {
            return new Promise((resolve, reject) => {
                return api.post("/payment/create-checkout-session", payload).then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        createPortalSession() {
            return new Promise((resolve, reject) => {
                return api.post("/payment/create-portal-session").then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        verifyPayment(store, payload){
            return new Promise((resolve, reject) => {
                return api.get(`/payment/verify-payment/${payload}`).then((response) => {
                    if (response.data) {
                        store.commit("storePaymentData", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        }
    }

}
