import api from '@/store/utils/api.js'
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        accountant: {
            email: null,
            firstName: null,
            lastName: null,
            fullName: null,
            phoneNumber: null,
            vatNumber: null,
        },
        link: {
            id: null,
            accountantId: null,
            status: null,
            created: null
        }
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        storeLink(state, link) {
            state.link = link;
        },
        storeAccountant(state, accountant) {
            state.accountant = accountant;
        },
    },
    actions: {
        getAccountant(store, accountantId) {
            return new Promise((resolve, reject) => {
                return api.get(`/accountant/search/${accountantId}`).then((response) => {
                    if (response.data) {
                        store.commit("storeAccountant", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        getUserLink(store) {
            return new Promise((resolve, reject) => {
                return api.get("/linkedUser/search").then((response) => {
                    if (response.data) {
                        store.commit("storeLink", response.data)
                    }
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        removeAccountant(store, requestId) {
            return new Promise((resolve, reject) => {
                return api.post(`/linkedUser/delete/${requestId}`).then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        rejectAccountant(store, requestId) {
            return new Promise((resolve, reject) => {
                return api.post(`/linkedUser/reject/${requestId}`).then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
        acceptAccountant(store, payload) {
            return new Promise((resolve, reject) => {
                return api.post(`/linkedUser/accept/${payload.requestId}`, payload).then((response) => {
                    resolve(response);
                }).catch((response) => reject(response));
            })
        },
    }

}
